import {
  Col,
  Modal,
  Row,
  Button,
  Popover,
  Layout,
  Avatar,
  Grid,
  Switch,
  Card,
} from "antd";
import {
  UserOutlined,
  MenuOutlined,
  BellOutlined,
  FileTextOutlined,
  MailOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  loadSentimentPower,
  sentimentPowerSelector,
} from "../../../appRedux/reducers/sentiment/sentiment-power";
import { nsUserSelector } from "../../../appRedux/reducers/sentiment/user";
import {
  loadXu30Daily,
  xu30DailySelector,
} from "../../../appRedux/reducers/sentiment/xu030";
import {
  loadXu100Daily,
  xu100DailySelector,
} from "../../../appRedux/reducers/sentiment/xu100";
import {
  dailyInstantSentimentSelector,
  loadDailyInstantSentiment,
} from "../../../appRedux/reducers/web/daily-instant-sentiment";
import { firebaseApp } from "../../../firebase";
import "react-circular-progressbar/dist/styles.css";
import chroma from "chroma-js";
import { toast } from "react-toastify";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import SidebarContent from "../../Sidebar/SidebarContent";
import "./index.css";
import MessagesPopover from "../../../components/HeaderComponents/MessagesPopover";
import UserPopover from "../../../components/HeaderComponents/UserPopover";
import PairCard from "./pairCard";
import {
  loadUserSession,
  userSessionSelector,
} from "../../../appRedux/reducers/sentiment/session";
import { useAuth } from "../../../authentication";
import GaugeComponent from "react-gauge-component";

const { Header } = Layout;
const { useBreakpoint } = Grid;

const POWER_SCALE = ["#ff0000", "#ff9933", "#FDDD60", "#39d121"];

export const CustomHeader = (props: any) => {
  const ex = 50;
  const screens = useBreakpoint();
  const intl = useIntl();
  const sentimentPowerIndicator = intl.formatMessage({
    id: "header.sentiment_power_indicator",
  });
  const [isReady, setIsReady] = useState(false);
  const [showPower, setShowPower] = useState(false);
  const [lastPowerDate, setLastPowerDate] = useState<Date | null>(null);
  const dispatch = useDispatch();
  const nsUser = useSelector(nsUserSelector);
  const { themeType } = useSelector(({ settings }: any) => settings);
  const { authUser, isLoadingUser } = useAuth();

  useEffect(() => {
    dispatch(loadUserSession(firebaseApp, authUser.uid));
    dispatch(loadXu100Daily(firebaseApp));
    dispatch(loadXu30Daily(firebaseApp));
    dispatch(loadDailyInstantSentiment(firebaseApp));
    dispatch(loadSentimentPower(firebaseApp));
  }, [nsUser]);

  useEffect(() => {
    if (showPower)
      setTimeout(() => {
        setIsReady(showPower);
      }, 500);
  }, [showPower]);

  const getXUDirection = (xuData: any) => {
    if (!xuData || xuData.length < 2) return "up";
    const last = xuData[xuData.length - 1];
    const previous = xuData[xuData.length - 2];
    if (last.close - previous.close >= 0) return "up";
    else return "down";
  };

  const getSentimentDirection = (data: any) => {
    if (!data || data.length < 2) return "up";
    const last = data[data.length - 1].value[1];
    const previous = data[data.length - 2].value[1];
    if (last - previous >= 0) return "up";
    else return "down";
  };

  const userSession = useSelector(userSessionSelector);
  const xu100Data = useSelector(xu100DailySelector);
  const xu30Data = useSelector(xu30DailySelector);
  const sentimentData = useSelector(dailyInstantSentimentSelector);
  const sentimentPowerData = useSelector(sentimentPowerSelector);
  const xu100Direction = getXUDirection(xu100Data?.xu100Daily);
  const xu30Direction = getXUDirection(xu30Data?.xu30Daily);
  const sentimentDirection = getSentimentDirection(sentimentData?.sentiment);

  const powerVal = useSelector(sentimentPowerSelector)?.power?.[0]?.value;

  useEffect(() => {
    try {
      if (sentimentPowerData?.loaded && sentimentPowerData?.power?.length > 0) {
        if (lastPowerDate != null) {
          if (
            moment(lastPowerDate).isBefore(
              sentimentPowerData?.power?.[0].dateTime
            )
          ) {
            if (
              "Notification" in window &&
              Notification.permission === "granted"
            ) {
              new Notification(`Sentiment Algo`, {
                body: `Güç indikatörü güncellendi.\nYeni: ${
                  sentimentPowerData?.power?.[0]?.value ?? 0
                }  Eski: ${sentimentPowerData?.power?.[1]?.value ?? 0}`,
              });
            }
            toast.info(
              `Güç indikatörü güncellendi. Yeni: ${
                sentimentPowerData?.power?.[0]?.value ?? 0
              }  Eski: ${sentimentPowerData?.power?.[1]?.value ?? 0}`,
              {
                autoClose: 5000,
                position: "bottom-right",
                hideProgressBar: true,
                closeOnClick: true,
              }
            );
          }
        }
        setLastPowerDate(sentimentPowerData?.power?.[0]?.dateTime);
      }
    } catch (e) {
      console.error("Power notification error", e);
    }
  }, [sentimentPowerData]);

  useEffect(() => {
    if (
      "Notification" in window &&
      Notification.permission !== "granted" &&
      Notification.permission !== "denied" &&
      localStorage.getItem("notification_permission_requested") !== "true"
    ) {
      localStorage.setItem("notification_permission_requested", "true");
      toast.info("Masaüstü bildirimlerine izin vermek için tıklayınız.", {
        delay: 2000,
        autoClose: 5000,
        position: "top-center",
        hideProgressBar: true,
        closeOnClick: true,
        onClick: () => {
          if ("Notification" in window) Notification.requestPermission();
        },
      });
    }
  }, []);

  const getSentimentPowerValue = (data: any) => {
    if (!data || data.length < 1) return 0;
    return data[0].value;
  };
  const getSentimentPowerDate = (data: any) => {
    if (!data || data.length < 1) return "";
    return moment(data[0].dateTime).format("DD.MM.YYYY HH:mm");
  };

  const handleKlineIntervalChange = (interval: any) => {
    localStorage.setItem("brokerage_kint", interval == false ? "1W" : "");
    window.location.reload();
  };

  const [userPopoverVisible, setUserPopoverVisible] = useState(false);

  const handleVisibleChange = (visible: any) => {
    setUserPopoverVisible(visible);
  };

  const closePopover = () => {
    setUserPopoverVisible(false);
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <div className="gx-header-horizontal">
      <Header
        className="gx-header-horizontal-main"
        style={{
          height: 60,
          padding: 0,
          display: "flex",
          backgroundColor:
            themeType === "THEME_TYPE_LITE" ? "#0e01df" : "#0e01df",
        }}
      >
        <div
          className="gx-container"
          style={{
            padding: screens.lg ? "0px 45px" : "0px 10px",
            width: "100%",
          }}
        >
          <div className="gx-header-horizontal-main-flex">
            {screens.xs ? (
              <div>
                <Button
                  style={{ marginTop: "40%" }}
                  icon={<MenuOutlined />}
                  onClick={toggleDrawer}
                />
              </div>
            ) : screens.lg ? (
              <Link to="/dashboard">
                <img
                  alt="logo2"
                  src={
                    themeType === "THEME_TYPE_LITE"
                      ? "/assets/images/logo.png?v=5"
                      : "/assets/images/logo.png?v=5"
                  }
                  style={{ height: 40, width: 185 }}
                />
              </Link>
            ) : (
              <div>
                <Button
                  style={{
                    marginTop: "8%",
                    backgroundColor:
                      themeType === "THEME_TYPE_LITE" ? "#eee" : "black",
                  }}
                  icon={
                    <MenuOutlined
                      style={{
                        color:
                          themeType === "THEME_TYPE_LITE" ? "black" : "white",
                      }}
                    />
                  }
                  onClick={toggleDrawer}
                />
                <Link to="/dashboard">
                  <img
                    alt="logo2"
                    src={
                      themeType === "THEME_TYPE_LITE"
                        ? // ? "/assets/images/logo-white.png"
                          "/assets/images/logo.png?v=5"
                        : "/assets/images/logo.png?v=5"
                    }
                    style={{ height: 30, width: 139 }}
                  />
                </Link>
              </div>
            )}

            {screens.lg && (
              <ul className="gx-header-notifications gx-ml-auto"></ul>
            )}

            <ul className="gx-header-notifications gx-ml-auto">
              {/* 
               mobile upper header menu icons area
              {!screens.lg && (
                <>
                  <li className="gx-notify" style={{ marginRight: 16 }}>
                    <Popover
                      overlayClassName={`${
                        screens.xs
                          ? "bp-mobile-header-popover"
                          : "gx-popover-horizantal bp-header-popover"
                      } `}
                      placement="bottomRight"
                      content={<MessagesPopover />}
                      trigger="click"
                      showArrow={screens.xs ? false : true}
                    >
                      <span className="gx-pointer gx-status-pos gx-d-block">
                        <Button
                          shape="circle"
                          size={screens.xs ? "small" : "middle"}
                          icon={<MailOutlined style={{ fontSize: 20 }} />}
                          style={{
                            padding: 0,
                            margin: 0,
                            backgroundColor: "transparent",
                            border: "none",
                            color: "white",
                          }}
                        />
                      </span>
                    </Popover>
                  </li>
                  <li className="gx-notify" style={{ marginRight: 16 }}>
                    <Popover
                      overlayClassName={`${
                        screens.xs
                          ? "bp-mobile-header-popover"
                          : "gx-popover-horizantal bp-header-popover"
                      } `}
                      placement="bottomRight"
                      trigger="click"
                      showArrow={screens.xs ? false : true}
                    >
                      <span className="gx-pointer gx-status-pos gx-d-block ">
                        <Button
                          shape="circle"
                          size={screens.xs ? "small" : "middle"}
                          style={{
                            padding: 0,
                            margin: 0,
                            backgroundColor: "transparent",
                            border: "none",
                            color: "white",
                          }}
                          icon={
                            <BellOutlined
                              className="bp-tada-animation"
                              style={{ fontSize: 20 }}
                            />
                          }
                        />

                        <span
                          className="gx-status gx-status-rtl gx-small gx-orange"
                          style={{ margin: screens.lg ? 6 : 4 }}
                        />
                      </span>
                    </Popover>
                  </li>
                  <li className="gx-notify" style={{ marginRight: 24 }}>
                    <Link to="/chat">
                      <span className="gx-pointer gx-d-block">
                        <Button
                          shape="circle"
                          size={screens.xs ? "small" : "middle"}
                          style={{
                            padding: 0,
                            margin: 0,
                            backgroundColor: "transparent",
                            border: "none",
                            color: "white",
                          }}
                          icon={<MessageOutlined style={{ fontSize: 20 }} />}
                        />
                      </span>
                    </Link>
                  </li>
                </>
              )} */}
              <li className="gx-notify">
                <div>
                  <Switch
                    style={{
                      backgroundColor: "#405af4",
                    }}
                    checkedChildren="G"
                    unCheckedChildren="H"
                    defaultChecked={
                      localStorage.getItem("brokerage_kint") == "1W"
                        ? false
                        : true
                    }
                    onChange={(v) => handleKlineIntervalChange(v)}
                  ></Switch>
                </div>
              </li>
              <li className="gx-notify">
                <Popover
                  overlayClassName={`${
                    screens.xs
                      ? "bp-mobile-header-popover"
                      : "gx-popover-horizantal bp-header-popover"
                  } bp-user-popover`}
                  placement="bottomRight"
                  content={
                    <UserPopover
                      setShowsSettings={props.setShowsSettings}
                      closePopover={closePopover}
                    />
                  }
                  trigger="click"
                  visible={userPopoverVisible}
                  onVisibleChange={handleVisibleChange}
                >
                  <Avatar
                    icon={
                      <UserOutlined
                        style={{
                          color:
                            themeType === "THEME_TYPE_LITE" ? "black" : "white",
                        }}
                      />
                    }
                    className="gx-avatar gx-pointer bp-boxshadow"
                    alt="user-avatar"
                    style={{
                      backgroundColor:
                        themeType === "THEME_TYPE_LITE" ? "#eee" : "black",
                      height: 36,
                      width: 36,
                    }}
                  />
                </Popover>
              </li>
            </ul>
          </div>
        </div>
      </Header>
      <section className="seperator-wrapper">
        <div className="seperator gradient"></div>
      </section>
      {screens.lg ? (
        <div
          className="gx-header-horizontal-nav gx-header-horizontal-nav-curve"
          style={{
            height: screens.xs ? 60 : 46,
            alignContent: "center",
            backgroundImage:
              themeType === "THEME_TYPE_LITE"
                ? "linear-gradient(to top, #060069 0%, #0e01df 100%)"
                : "linear-gradient(to top, #060069 0%, #0e01df 100%)",
          }}
        >
          <div
            className="gx-container"
            style={{
              padding: "0px 25px",
              width: "100%",
            }}
          >
            <div className="gx-header-horizontal-nav-flex">
              <SidebarContent
                isDrawerOpen={isDrawerOpen}
                closeDrawer={() => setIsDrawerOpen(false)}
              />
              {/* upperheader altındaki menü alanı mobilde boş olduğu için, bu alana sadece masaüstü görünümünde görünme şartı eklendi */}
              {screens.lg ? (
                <ul className="gx-header-notifications gx-ml-auto">
                  {/*
                <li className="gx-notify" style={{ marginRight: 8 }}>
                  <Popover
                    overlayClassName="gx-popover-horizantal bp-header-popover"
                    placement="bottomRight"
                    content={<MessagesPopover />}
                    trigger="click"
                  >
                    <span className="gx-pointer gx-status-pos gx-d-block">
                      <Button
                        shape="circle"
                        size={screens.xs ? "small" : "middle"}
                        icon={<MailOutlined style={{ fontSize: 20 }} />}
                        style={{
                          padding: 0,
                          margin: 0,
                          backgroundColor: "transparent",
                          border: "none",
                          color: "white",
                        }}
                      />
                    </span>
                  </Popover>
                </li> */}

                  {/* <li className="gx-notify" style={{ marginRight: 8 }}>
                  <span className="gx-pointer gx-status-pos gx-d-block ">
                    <Button
                      shape="circle"
                      size={screens.xs ? "small" : "middle"}
                      style={{
                        padding: 0,
                        margin: 0,
                        backgroundColor: "transparent",
                        border: "none",
                        color: "white",
                      }}
                      icon={
                        <BellOutlined
                          className="bp-tada-animation"
                          style={{ fontSize: 20 }}
                        />
                      }
                    />
                    <span
                      className="gx-status gx-status-rtl gx-small gx-orange"
                      style={{ margin: screens.lg ? 6 : 4 }}
                    />
                  </span>
                </li> */}

                  {/* <li className="gx-notify" style={{ marginRight: 16 }}>
                  <Link to="/chat">
                    <span className="gx-pointer gx-d-block">
                      <Button
                        shape="circle"
                        size={screens.xs ? "small" : "middle"}
                        style={{
                          padding: 0,
                          margin: 0,
                          backgroundColor: "transparent",
                          border: "none",
                          color: "white",
                        }}
                        icon={<MessageOutlined style={{ fontSize: 20 }} />}
                      />
                    </span>
                  </Link>
                </li> */}
                </ul>
              ) : (
                <ul
                  className="gx-header-notifications"
                  style={{ width: "100%", justifyContent: "center" }}
                ></ul>
              )}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {showPower && (
        <Modal
          transitionName=""
          title={`${sentimentPowerIndicator} (${getSentimentPowerDate(
            sentimentPowerData?.power
          )})`}
          className="chart-container"
          open={showPower}
          onCancel={() => setShowPower(false)}
          footer={null}
          bodyStyle={{ minHeight: 500 }}
        >
          {/* <SentimentPower
            power={
              sentimentPowerData?.power?.length > 0
                ? sentimentPowerData?.power[0].value
                : 0
            }
          /> */}
          {isReady && (
            <GaugeComponent
              type="radial"
              className={
                powerVal >= 25 && ex < 50
                  ? "bp-custom-gauge-low"
                  : ex >= 50 && ex < 75
                  ? "bp-custom-gauge-normal"
                  : powerVal >= 75
                  ? "bp-custom-gauge-high"
                  : ""
              }
              pointer={{
                length: 0.8,
                width: 8,
                elastic: true,
                color: themeType === "THEME_TYPE_LITE" ? "#262626" : "white",
              }}
              arc={{
                width: 0.1,
                padding: 0.03,
                cornerRadius: 4,
                subArcs: [
                  { limit: 25, color: "#ff4d4f", showTick: true },
                  { limit: 50, color: "#faad14", showTick: true },
                  { limit: 75, color: "#fae714", showTick: true },
                  { limit: 100, color: "#52C41A", showTick: true },
                ],
              }}
              value={powerVal}
              maxValue={100}
              minValue={0}
            />
          )}
        </Modal>
      )}
    </div>
  );
};
