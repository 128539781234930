export enum SENTIMENT_ACTION {
  SP500_DAILY_BALANCES = "SP500_DAILY_BALANCES",
  TRADE_SP500 = "TRADE_SP500",
  SIGNAL_SP500 = "SIGNAL_SP500",
  LINES_SP500 = "LINES_SP500",
  XU030_DAILY = "XU030_DAILY",
  XU100_DAILY = "XU100_DAILY",
  XU100_MONTHLY_BALANCE = "XU100_MONTHLY_BALANCE",
  DAILY_BALANCES = "DAILY_BALANCES",
  DAILY_BALANCES_EXTENDED = "DAILY_BALANCES_EXTENDED",
  XSEN030_MONTHLY_BALANCE = "XSEN030_MONTHLY_BALANCE",
  XSEN070_MONTHLY_BALANCE = "XSEN070_MONTHLY_BALANCE",
  XSEN500_MONTHLY_BALANCE = "XSEN500_MONTHLY_BALANCE",
  XSEN030_DAILY_BALANCE = "XSEN030_DAILY_BALANCE",
  XSEN070_DAILY_BALANCE = "XSEN070_DAILY__BALANCE",
  XSEN500_DAILY_BALANCE = "XSEN500_DAILY_BALANCE",
  DAILY_NDX = "DAILY_NDX",
  PORTFOLIO = "PORTFOLIO",
  NEWS = "NEWS",
  NOTIFICATIONS = "notifications",
  DAILY_SUGGESTIONS = "DAILY_SUGGESTIONS",
  LINES_30 = "LINES_30",
  LINES_70 = "LINES_70",
  LINES_200 = "LINES_200",
  LINES_400 = "LINES_400",
  LINES_500 = "LINES_500",
  LINES_99 = "LINES_99",
  LINES_30_SNAPSHOT = "LINES_30_SNAPSHOT",
  LINES_70_SNAPSHOT = "LINES_70_SNAPSHOT",
  LINES_200_SNAPSHOT = "LINES_200_SNAPSHOT",
  LINES_400_SNAPSHOT = "LINES_400_SNAPSHOT",
  LINES_500_SNAPSHOT = "LINES_500_SNAPSHOT",
  SIGNAL_30 = "SIGNAL_30",
  SIGNAL_70 = "SIGNAL_70",
  SIGNAL_200 = "SIGNAL_200",
  SIGNAL_400 = "SIGNAL_400",
  SIGNAL_500 = "SIGNAL_500",
  SIGNAL_99 = "SIGNAL_99",
  WORST_30 = "WORST_30",
  WORST_70 = "WORST_70",
  WORST_500 = "WORST_500",
  TRADE_30 = "TRADE_30",
  TRADE_70 = "TRADE_70",
  TRADE_200 = "TRADE_200",
  TRADE_400 = "TRADE_400",
  SHORTTRADE_30 = "SHORTTRADE_30",
  SHORTTRADE_70 = "SHORTTRADE_70",
  TRADE_500 = "TRADE_500",
  NS_USER = "NS_USER",
  NS_USER_SESSION = "NS_USER_SESSION",
  NS_LINES_DATE_LIST = "NS_LINES_DATE_LIST",
  BIST_TOP_30 = "NS_BIST_TOP_30",
  BIST_TOP_70 = "NS_BIST_TOP_70",
  BIST_PERFORMANCE_DAILY = "BIST_PERFORMANCE_DAILY",
  BIST_PERFORMANCE_WEEKLY = "BIST_PERFORMANCE_WEEKLY",
  BIST_PERFORMANCE_MONTHLY = "BIST_PERFORMANCE_MONTHLY",
  EMPTY_ACTION = "EMPTY_ACTION",
  SENTIMENT_POWER = "SENTIMENT_POWER",

  FX_SIGNAL_30 = "FX_SIGNAL_30",
  FX_SIGNAL_40 = "FX_SIGNAL_40",
  FX_SIGNAL_50 = "FX_SIGNAL_50",
  FX_SIGNAL_99 = "FX_SIGNAL_99",

  FX_LINES_30 = "FX_LINES_30",
  FX_LINES_40 = "FX_LINES_40",
  FX_LINES_50 = "FX_LINES_50",
  FX_LINES_99 = "FX_LINES_99",

  FX_BALANCE_30 = "FX_BALANCE_30",
  FX_BALANCE_40 = "FX_BALANCE_40",
  FX_BALANCE_50 = "FX_BALANCE_50",
  FX_BALANCE_99 = "FX_BALANCE_99",

  FX_TRADE_30 = "FX_TRADE_30",
  FX_TRADE_40 = "FX_TRADE_40",
  FX_TRADE_50 = "FX_TRADE_50",
  FX_TRADE_99 = "FX_TRADE_99",

  PORTFOLIO_30 = "PORTFOLIO_30",

  NIFTY_LINES_20 = "NIFTY_LINES_20",
  NIFTY_LINES_30 = "NIFTY_LINES_30",
  NIFTY_LINES_50 = "NIFTY_LINES_50",
  NIFTY_SIGNAL_20 = "NIFTY_SIGNAL_20",
  NIFTY_SIGNAL_30 = "NIFTY_SIGNAL_30",
  NIFTY_SIGNAL_50 = "NIFTY_SIGNAL_50",
  NIFTY_TRADE_20 = "NIFTY_TRADE_20",
  NIFTY_TRADE_30 = "NIFTY_TRADE_30",
  NIFTY_TRADE_50 = "NIFTY_TRADE_50",
  NIFTY_BALANCES = "NIFTY_BALANCES",
  NS_NIFTYLINES_DATE_LIST = "NS_NIFTYLINES_DATE_LIST",
}
