import moment from "moment";
import sectorAndIndustries from "../../../util/sectorAndIndustries";

export const linesMapper = (doc: any, count: number = 1) => {
    var sentiPoints = doc.sentimentList;
    var SentiPer = 0
    if (sentiPoints?.length > 0)
        SentiPer = ((sentiPoints[sentiPoints.length - 1] - sentiPoints[sentiPoints.length - 2]) / sentiPoints[sentiPoints.length - 2] * 100);
    let tickerInfo = sectorAndIndustries.filter((x: any) => { return x.Symbol == doc.symbol })[0] || { Company: "", Sector: "", Industry: "" };
    const STP = doc.vwma?.reverse();

    return {
        key: `${doc.time}_${doc.symbol}`,
        count: count,
        Index: doc.index,
        Symbol: doc.symbol,
        Sentiment: doc.sentiment,
        Order: doc.order,
        Group: doc.group,
        ODate: doc.openDate,
        OPrice: doc.openPrice,
        Price: doc.price,
        DailyChange: doc.dailyChange,
        Vbts: false,
        First5to10: false,
        IsNewFirst5: false,
        Viop: false,
        PerDay: doc.perDay,
        SentimentList: doc.sentimentList,
        PriceList: doc.priceList,
        ChangeList: doc.changeList,
        ReChangeList: doc.changeList?.slice().reverse(),
        dateTime: doc.dateTime?.toDate(),
        Time: doc.time ?? 0,
        UpdateTime: moment
            .unix(doc.dateTime?.seconds)
            .format("DD.MM.yy HH:mm"),
        LineDate: doc.dateTime?.seconds,
        VWMA: doc.vwma,
        SentiPer: Math.round(SentiPer * 100) / 100,
        STP: STP,
        //MTP: doc.mtp,

        Diff_STP: STP && STP.length > 0 ? parseFloat(((doc.price - STP[STP.length - 1]) / STP[STP.length - 1] * 100).toFixed(2)) : "",
        //Diff_MTP: doc.mtp && doc.mtp.length > 0 ? parseFloat(((doc.price - doc.mtp[doc.mtp.length - 1]) / doc.mtp[doc.mtp.length - 1] * 100).toFixed(2)) : "",

        CurrentSTP: STP?.length > 0 ? STP[STP.length - 1] : 0,
        //CurrentMTP: doc.mtp?.length > 0 ? doc.mtp[doc.mtp.length - 1] : 0
        Company: tickerInfo.Company,
        Sector: tickerInfo.Sector,
        Industry: tickerInfo.Industry,
    };

}