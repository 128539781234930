import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  nsUserSelector,
} from "../appRedux/reducers/sentiment/user";

import asyncComponent from "util/asyncComponent";
import { useAuth } from "../authentication";
import { useAppDispatch } from "../NextApp";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Payment from "./Payment";
import axios from "axios";

const App = ({ match }) => {
  const [agreed, setAgreed] = useState(false);
  const [waitingAgreement, setWaitingAgreement] = useState(false);
  const { authUser } = useAuth();

  const dispatch = useAppDispatch();
  const nsUser = useSelector(nsUserSelector);

  useEffect(() => {
    console.log(`Env: ${process.env.REACT_APP_ENV}`);
  }, []);

  useEffect(() => {
    try {
      if (nsUser?.loaded && authUser) {
        if (process.env.REACT_APP_ZEPHLEX_TRACKER_URL) {
          const time = localStorage.getItem("zephlex_utl");
          let update = true;
          if (time && !isNaN(time)) {
            const diff = new Date().getTime() - parseInt(time);
            if (diff < 1000 * 60 * 5) {
              update = false;
            }
          }

          if (update) {
            localStorage.setItem("zephlex_utl", new Date().getTime());
            axios.get(process.env.REACT_APP_ZEPHLEX_TRACKER_URL, {
              headers: {
                "x-user-id": authUser?.uid,
              },
            });
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

 
  const noPadding = window.location.pathname.includes("/hub");
  return (
    <div
      className={`gx-main-content-wrapper ${
        noPadding ? "main-content-no-padding" : ""
      }`}
    >
      <Switch>
        <Route
          path={`${match.url}dashboard`}
          component={asyncComponent(() => import("./Dashboard3"))}
        />
        <Route
          path={`${match.url}usa`}
          component={asyncComponent(() => import("./USA"))}
        />
        <Route
          path={`${match.url}fx`}
          component={asyncComponent(() => import("./FX"))}
        />
        <Route
          path={`${match.url}messages`}
          component={asyncComponent(() => import("./Messages"))}
        />
        <Route
          path={`${match.url}help`}
          component={asyncComponent(() => import("./Help"))}
        />
        <Route
          path={`${match.url}account`}
          component={asyncComponent(() => import("./Account"))}
        />
        <Route
          path={`${match.url}dashboard3`}
          component={asyncComponent(() => import("./Dashboard3"))}
        />
        <Route
          path={`${match.url}notifications`}
          component={asyncComponent(() => import("./Notifications"))}
        />
        <Route
          path={`${match.url}changelog`}
          component={asyncComponent(() => import("./ChangeLog"))}
        />
        <Route
          path={`${match.url}chat`}
          component={asyncComponent(() => import("./Chat"))}
        />
        <Route path={`${match.url}payment`} component={Payment} />
      </Switch>
      <ToastContainer></ToastContainer>
    </div>
  );
};

export default App;
