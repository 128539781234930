import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";
import { auth } from "../../../firebase";

const COLLECTION_NAME = "NS_CORPORATE_SESSIONS";

interface IXUData {
  isValid: boolean,
  requested: boolean;
  loaded: boolean;
}

const initialState: IXUData = {
  isValid: false,
  requested: false,
  loaded: false,
};

export function loadUserSession(firebase: any, userId: string) {
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (state.UserSession.requested) return;
    dispatch({
      type: SENTIMENT_ACTION.NS_USER_SESSION,
      payload: { requested: true, loaded: false, sentiment: [] },
    });

    firebase
      .firestore()
      .collection(COLLECTION_NAME)
      .doc(userId)
      .onSnapshot(
        async (doc: any) => {
          let isValid = false;
          // isValid = doc.data().sessionId === localStorage.getItem("sid");
          // if (!isValid) {
          //   auth.signOut();
          // }

          dispatch({
            type: SENTIMENT_ACTION.NS_USER_SESSION,
            payload: {
              isValid: isValid,
              loaded: true,
              requested: true,
            },
          });
        });
  };
}

export default function UserSession(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.NS_USER_SESSION:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const userSessionSelector = (state: any) => state.UserSession;
