import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";
import { linesMapper } from "../mapper/mapper";

const DATA_COUNT = 160;
const COLLECTION_NAME = "NS_NASDAQ_LINES_500";

interface IXUData {
  lines500: IData[];
  requested: boolean;
  loaded: boolean;
  lastUpdate: number;
}

const initialState: IXUData = {
  lines500: [],
  requested: false,
  loaded: false,
  lastUpdate: 0
};

export function loadLines500Snapshot(firebase: any, period: number) {
  if (period < 0 || ![0, 10, 30, 99].includes(period)) {
    console.log("Invalid period for line 500. Period:", period);
    return async function thunk(dispatch: any, getState: any) {
      dispatch({
        type: SENTIMENT_ACTION.EMPTY_ACTION,
        payload: {},
      });
    };
  }
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (new Date().getTime()-state.Lines500Snapshot.lastUpdate<60*1000) return;

    dispatch({
      type: SENTIMENT_ACTION.LINES_500_SNAPSHOT,
      payload: { requested: true, loaded: false, sentiment: [] },
    });

    firebase
      .firestore()
      .collection(`${COLLECTION_NAME}_P${period}`)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .get().then((querySnapshot: any) => {
        var lines: Object[] = [];
        querySnapshot.forEach(function (doc: any) {
          lines.push(linesMapper(doc.data(), DATA_COUNT));
        });
        dispatch({
          type: SENTIMENT_ACTION.LINES_500_SNAPSHOT,
          payload: {
            lines500: lines.sort((a: any, b: any) => { return a.Order - b.Order }),
            loaded: true,
            requested: true,
            lastUpdate:new Date().getTime()
          },
        });
      });
  };
}

export default function Lines500Snapshot(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.LINES_500_SNAPSHOT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const lines500SnapshotSelector = (state: any) => state.Lines500Snapshot;
