import React from "react";
import { Button, Checkbox, Form, Input } from "antd";
import { Link } from "react-router-dom";
import { useCreateUserWithEmailAndPassword } from "react-firebase-hooks/auth";

import IntlMessages from "util/IntlMessages";
import { useAuth } from "../authentication";
import AppNotificationContainer from "../components/AppNotificationContainer";
import { auth } from "../firebase";
import { useHistory } from "react-router-dom";
import firebase from "firebase/app";
import { useIntl } from "react-intl";
const FormItem = Form.Item;

const SignUp = () => {
  const intl=useIntl()
  const { isLoading, error, userSignup } = useAuth();
  const history = useHistory();

  const [
    createUserWithEmailAndPassword,
    _emailUser,
    _emailUserLoading,
    emailSignupError,
  ] = useCreateUserWithEmailAndPassword(auth);
  const onFinishFailed = (errorInfo) => {};

  const onFinish = async (values) => {
    let userCredential = null;
    try {
      userCredential = await createUserWithEmailAndPassword(
        values.email,
        values.password
      );
    } catch {}
    if (userCredential) {
      history.push("/dashboard");
    }
  };
  const getemailSignupErrorText = (code) => {
    switch (code) {
      case "auth/email-already-in-use":
        return "Başka bir e-mail ile kayıt olmayı deneyin";
      case "auth/invalid-email":
        return "Geçersiz e-mail adresi";
      case "auth/weak-password":
        return "Lütfen daha güçlü bir parola belirleyin";
      case "auth/operation-not-allowed":
        return "Kullanıcı hesabı aktif değil";
    }
    return "Kayıt sırasında hata oluştu";
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img src="https://via.placeholder.com/272x395" alt="Neature" />
            </div>
            <div className="gx-app-logo-wid"></div>
            <div className="gx-app-logo">
              <img alt="example" src="/assets/images/logo.png?v=5" />
            </div>
          </div>

          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
            >
              {emailSignupError && emailSignupError.code && (
                <Form.Item>
                  <div class="ant-form-item-explain-error">
                    <div role="alert">
                      {getemailSignupErrorText(emailSignupError.code)}
                    </div>
                  </div>
                </Form.Item>
              )}
              <FormItem
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Geçersiz e-mail",
                  },
                ]}
              >
                <Input placeholder={intl.formatMessage({id:"page.login.email"})} />
              </FormItem>
              <FormItem
                name="password"
                rules={[{ required: true, message: "Lütfen parolanızı girin" }]}
              >
                <Input type="password" placeholder={intl.formatMessage({id:"page.login.password"})} />
              </FormItem>

              <FormItem>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="page.login.signup"/>
                </Button>
                <span><IntlMessages id="page.login.or"/></span> <Link to="/signin"><IntlMessages id="page.login.do_login"/></Link>
              </FormItem>
              <Button
                style={{
                  display: "flex",
                  gap: 10,
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "center",
                }}
                onClick={() => {
                  var provider = new firebase.auth.GoogleAuthProvider();
                  auth.signInWithPopup(provider).then(() => {
                    history.push("/dashboard");
                  });
                }}
              >
                <img height="16" src="/assets/images/google.png" />
                <IntlMessages id="page.login.signup_with_google"/>
              </Button>
            </Form>
          </div>
          <AppNotificationContainer loading={isLoading} error={error} />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
