import moment from "moment";
import { SENTIMENT_ACTION } from "./actions";
import { IData } from "../web/common";
import klineInterval from "../Params";
import { linesMapper } from "../mapper/mapper";

const DATA_COUNT = 23;
const COLLECTION_NAME = "NS_FX_LINES_40";

interface IXUData {
  lines40: IData[];
  requested: boolean;
  loaded: boolean;
  lastUpdate: number;
}

const initialState: IXUData = {
  lines40: [],
  requested: false,
  loaded: false,
  lastUpdate: 0
};

export function loadFXLines40Snapshot(firebase: any, period: number, bars: number = 0) {
  if (period < 0 || ![0, 10, 30, 99].includes(period)) {
    console.log("Invalid period for line 40. Period:", period);
    return async function thunk(dispatch: any, getState: any) {
      dispatch({
        type: SENTIMENT_ACTION.EMPTY_ACTION,
        payload: {},
      });
    };
  }
  return async function thunk(dispatch: any, getState: any) {
    const state = getState();
    if (new Date().getTime() - state.FXLines30Snapshot.lastUpdate < 60 * 1000 && state.bars == bars) return;

    dispatch({
      type: SENTIMENT_ACTION.FX_LINES_40,
      payload: { requested: true, loaded: false, sentiment: [] },
    });
    firebase
      .firestore()
      .collection(`${COLLECTION_NAME}${klineInterval}_P${period}`)
      .orderBy("dateTime", "desc")
      .limit(DATA_COUNT)
      .get().then((querySnapshot: any) => {
        var lines: Object[] = [];
        querySnapshot.forEach(function (doc: any) {
          lines.push(linesMapper(doc.data(), DATA_COUNT));
        });
        dispatch({
          type: SENTIMENT_ACTION.FX_LINES_40,
          payload: {
            lines40: lines.sort((a: any, b: any) => { return a.Order - b.Order }),
            loaded: true,
            requested: true,
            lastUpdate:new Date().getTime()
          },
        });
      });
  };
}

export default function FXLines40Snapshot(state = initialState, action: any) {
  switch (action.type) {
    case SENTIMENT_ACTION.FX_LINES_40:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
export const FXlines40SnapshotSelector = (state: any) => state.FXLines40Snapshot;
