import { useEffect, useState } from "react";
import { Menu, Dropdown, Button, Popover, Row, Col, Typography } from "antd";
import { createFromIconfontCN, GlobalOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { setDirectionRTL, switchLanguage } from "../../appRedux/actions";
import AppLocale from "../../lngProvider";
import CustomScrollbars from "../../util/CustomScrollbars";

const { Text } = Typography;

const LanguageMenu = (props: any) => {
  const dispatch = useDispatch();
  const { themeType } = useSelector(({ settings }: any) => settings);
  const intl = useIntl();
  const languageOptions = [
    {
      key: "tr",
      name: intl.formatMessage({ id: "settings.lang.turkish" }),
      icon: "tr",
      lang: "turkish",
    },
    {
      key: "az",
      name: intl.formatMessage({ id: "settings.lang.azerbaijani" }),
      icon: "az",
      lang: "azerbaijani",
    },
    {
      key: "en",
      name: intl.formatMessage({ id: "settings.lang.english" }),
      icon: "us",
      lang: "english",
    },
    {
      key: "de",
      name: intl.formatMessage({ id: "settings.lang.german" }),
      icon: "de",
      lang: "german",
    },
    {
      key: "ar",
      name: intl.formatMessage({ id: "settings.lang.arabic" }),
      icon: "sa",
      lang: "arabic",
    },
  ];
  const getInitialLanguage = () => {
    const key = localStorage.getItem("lang");
    const selected = languageOptions.find((item) => item.key === key);
    if (selected) return selected;
    return languageOptions[0];
  };
  const [selectedLanguage, setSelectedLanguage] = useState(
    getInitialLanguage()
  );

  const handleLanguageSelect = (language: any) => {
    const lang = language.key;
    localStorage.setItem("lang", lang);
    localStorage.setItem("isRTL", lang === "ar" ? "true" : "false");
    if (lang === "ar") dispatch(setDirectionRTL(true));
    else dispatch(setDirectionRTL(false));

    dispatch(switchLanguage((AppLocale as any)[lang]));
    setSelectedLanguage(language);
  };

  const languageMenu = (
    <CustomScrollbars
      style={{ minHeight: "184px", width: "160px", margin: "0 -16px" }}
    >
      <ul className="gx-sub-popover">
        {languageOptions.map((language) => (
          <li
            className="gx-media gx-pointer"
            key={language?.key}
            onClick={() => handleLanguageSelect(language)}
            style={{ padding: "6px 0" }}
          >
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text gx-mt-1">{language.name}</span>
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  );

  useEffect(() => {
    const updatedLanguage = languageOptions.find(
      (lang) => lang.key === selectedLanguage.key
    );
    if (updatedLanguage) {
      setSelectedLanguage({
        ...updatedLanguage,
        name: intl.formatMessage({
          id: `settings.lang.${updatedLanguage.lang}`,
        }),
      });
    }
  }, [selectedLanguage.key, intl]);

  return (
    <Popover
      overlayClassName="gx-popover-horizantal bp-lang-popover"
      placement="leftBottom"
      content={languageMenu}
      trigger="click"
    >
      <Row align="middle" style={{ padding: "10px 10px 10px 0px" }}>
        <Col span={4}>
          <GlobalOutlined style={{ fontSize: 18 }} />
        </Col>
        <Col span={8}>
          <Text
            strong
            style={{
              color: themeType === "THEME_TYPE_DARK" ? "white" : "",
            }}
          >
            Dil
          </Text>
        </Col>
        <Col span={12}>
          <Row
            align="middle"
            justify="space-evenly"
            style={{ border: "1px solid #dbdfe9", borderRadius: 6 }}
          >
            <i className={`flag flag-24 flag-${selectedLanguage?.icon}`} />
            <span
              className="gx-pl-2 gx-language-name"
              style={{
                maxWidth: 77,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {selectedLanguage?.name}
            </span>
          </Row>
        </Col>
      </Row>
    </Popover>
  );
};

export default LanguageMenu;
