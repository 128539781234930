export const PERMISSIONS = {
  NASDAQ: "nasdaq.all",
  SENTISPX: "sentiSpx.all",
  FX: "fx.all",
};

export const isCooperateUser = (nsUser: any) => {
  if (!nsUser || !nsUser?.user) return false;
  if (nsUser?.package?.admin) return true;
  return false;
};

export const userHasPermission = (nsUser: any, permission: string) => {
  if (!nsUser || !nsUser?.user) return false;
  return nsUser?.user?.[permission] === true;
};

export const hasPermission = (nsUser: any, permission: string) => {
  if (!nsUser || !nsUser?.user) return false;
  if (isCooperateUser(nsUser)) return true;
  return nsUser?.user?.[permission] === true;
};

export const hasOneOfPermissions = (nsUser: any, permissions: string[]) => {
  if (!nsUser || !nsUser?.user) return false;
  if (isCooperateUser(nsUser)) return true;
  return permissions.some((permission) => nsUser?.user?.[permission] === true);
};